import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storageName: string = "isOpenedbefore";
  customerCodeName: string = "customerCode";
  isLayoutLoadedName: string = "isLayoutLoaded";
  isPageLoadedName: string = "isLayoutLoaded";

  constructor() { }

  setStorage(storageName: string) {
    localStorage.setItem(this.storageName, storageName);
  }
  setCustomerCode(customerCode: string) {
    localStorage.setItem(this.customerCodeName, customerCode);
  }
  
  setLayoutLoaded(isLoaded: string) {
    localStorage.setItem(this.isLayoutLoadedName, isLoaded);
  }
  setPageLoaded(isLoaded: string) {
    localStorage.setItem(this.isPageLoadedName, isLoaded);
  }
  getSorage() {
    let token: string = localStorage.getItem(this.storageName);
    // alert("token"+token)
    if (token == null || token == 'undefined') {
      return "";
    }
    return token;
  }
  getCustomerCode() {
    let code: string = localStorage.getItem(this.customerCodeName);
    if (code == null || code == 'undefined') {
      return "";
    }
    return code;
  }
  getLayoutIsLoaded() {
    let storage: string = localStorage.getItem(this.isLayoutLoadedName);
    if (storage == null || storage == 'undefined')
      return "";
    return storage;
  }
  getPageIsLoaded() {
    let storage: string = localStorage.getItem(this.isPageLoadedName);
    if (storage == null || storage == 'undefined')
      return "";
    return storage;
  }
  removeStrage() {
    localStorage.setItem(this.storageName, "");
    localStorage.removeItem(this.storageName);
  }
}
