import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private sharedService: SharedService
  ) {

  }
  ngOnInit(): void {
    this.sharedService.setPorjectIsLoaded(false)
    this.storageService.removeStrage();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.storageService.setStorage("yes")
      }, (err => {
      })
      )

  }

  // isLoaded(): boolean {
  //   return this.sharedService.getPorjectIsLoaded()
  // }
} 
