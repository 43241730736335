import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerRoutingModule } from './customer-routing.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CustomerRoutingModule
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
