import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class LayuutService {
  controller : string = "customer/Account";
  constructor(private apiService:ApiService) { }

  getMainInfo(code : string){
    return this.apiService.get(`/${this.controller}/GetCompanyMainInfo?code=${code}`)
  }
  
}
