
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { ResponseViewModel } from '../models/shared/response.model';
import { DateService } from './date.service';
import { LogService } from './log.service';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { LocationViewModel } from '../models/shared/location-view-model';
import { LocalizationService } from './localization.service';
import { Language } from '../enums/language';
import { MainInfoViewModel } from '../components/shared/layout/main-info.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  customerCodeStatus=new BehaviorSubject<ResponseViewModel>(new ResponseViewModel());
  companyMainInfoStatus=new BehaviorSubject<ResponseViewModel>(new ResponseViewModel());
  private subject = new Subject<any>();
  data :any
  audio = new Audio();

  // public isPageLoaded :boolean= false
  // public isLayoutLoaded :boolean= false

  changeCodeStatus(res:ResponseViewModel)
  {
    this.customerCodeStatus.next(res);
  }
  getCodeStatus():Observable<ResponseViewModel>{
    return this.customerCodeStatus.asObservable();
  }
  
  changeCompanyMainInfoStatus(res:ResponseViewModel)
  {
    this.companyMainInfoStatus.next(res);
  }
  getCompanyMainInfoStatus():Observable<ResponseViewModel>{
    return this.companyMainInfoStatus.asObservable();
  }
  public geocoder: google.maps.Geocoder ;

  constructor(
    public formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public router:Router,
    private toastr: ToastrService,
    public modalService: BsModalService,
    public dateService:DateService,
    public _logService:LogService,
    private storageService:StorageService,
    private _localizationService:LocalizationService
  ) {
    // this.geocoder = new google.maps.Geocoder()
  }
    
  getCurrentMoule():string{
    return this.router.url.split('/')[2]
  }
  getCurrenPage():string{
    return this.router.url.split('/')[1]
  }
  showToastr(response:ResponseViewModel) {
    if(response.Success){
      this.toastr.success('',response.Message,{
        toastClass:'successToastClass'
      });
    }
    else{
      this.toastr.error('', response.Message,{
        toastClass:'errorToastClass'
      });
    }
  }
  showErrorAlert(message:string) {
    this.toastr.error('', message,{
      toastClass:'errorToastClass'
    });
  }
  showSuccessAlert(message:string){
    this.toastr.success('',message,{
      toastClass:'successToastClass'
    });
  }
  showInfoAlert(message:string){
    this.toastr.success('',message,{
      toastClass:'successToastClass infoToastClass'
    });
  }
  playAudio(path:string) {
    this.audio.src = path;
    this.audio.load();
    this.audio.play()
  }

  sendData(data: any) {
      this.subject.next(data);
  }

  clearData() {
    this.subject.unsubscribe()
    this.subject.next();
  }

  getData(): Observable<any> {
      return this.subject.asObservable();
  }
  // setIsLoadedPorject(isLoaded:boolean){
  //   this.isLayoutLoaded = isLoaded
  //   this.isPageLoaded = isLoaded
  // }
  setPorjectIsLoaded(isLoaded:any){
    this.storageService.setLayoutLoaded(isLoaded)
    this.storageService.setPageLoaded(isLoaded)
  }
  setLayoutLoaded(isLoaded:any){
    this.storageService.setLayoutLoaded(isLoaded)
  }
  setPageLoaded(isLoaded:any){
    this.storageService.setLayoutLoaded(isLoaded)
  }
  getLayoutIsLoaded():boolean{
    if(this.storageService.getLayoutIsLoaded()== 'false')
    return false
    return true
  }
  getPageIsLoaded():boolean{
    if(this.storageService.getPageIsLoaded()== 'false')
    return false
    return true
  }
  getPorjectIsLoaded():boolean{
    if(this.storageService.getLayoutIsLoaded()== 'false' 
      || this.storageService.getPageIsLoaded()== 'false')
      return false
    return true
  }
  getLoaction(){
    let location:LocationViewModel = new LocationViewModel()
    return new Promise ((resolve)=>{
      navigator.geolocation.getCurrentPosition((position=>{
        location.Latitude = position.coords.latitude
        location.Longitude = position.coords.longitude
        resolve(location)
      }))
    })
  }
  copyText(val: any) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  setImagePlaceholder(img:{imageID:string,imagePath:string,placeholderPath:string}) {
    let image:HTMLElement = document.getElementById(img.imageID)
    image.style.backgroundImage = `url(${img.imagePath}),url(${img.placeholderPath})`
    image.style.backgroundRepeat = 'no-repeat'
    image.style.backgroundSize = 'contain'
  }
  getCurrentLanguage():Language{
    return this._localizationService.getLanguage()=='en'? Language.EN :Language.AR
  }
  // geoCode(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult[]> {
  //   return Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
  //       // Invokes geocode method of Google Maps API geocoding.
  //       this.geocoder.geocode({ location: latLng }, (
  //           (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
  //               if (status === google.maps.GeocoderStatus.OK) {
  //                   observer.next(results);
  //                   observer.complete();
  //               } else {
  //                   console.log('Geocoding service: geocoder failed due to: ' + status);
  //                   observer.error(status);
  //               }
  //           })
  //       );
  //   });
  // }
  // public getAddressFromLatLng(query: string) {
  //   return new Promise((resolve, reject) => {
  //       this.geocoder.reverseGeocode({ prox: query, mode: "retrieveAddress" }, result => {
  //           if(result.Response.View.length > 0) {
  //               if(result.Response.View[0].Result.length > 0) {
  //                   resolve(result.Response.View[0].Result);
  //               } else {
  //                   reject({ message: "no results found" });
  //               }
  //           } else {
  //               reject({ message: "no results found" });
  //           }
  //       }, error => {
  //           reject(error);
  //       });
  //   });
  // }
  loadJsFiles(url) {
    let node = document.createElement('script');
    node.src = url;
    node.id = 'animation';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  removeAnimationScript(){
    const scriptList = document.querySelectorAll("script")
    let animationScript = Array.from(scriptList).find(script => script.id == "animation")
    if(animationScript)
      animationScript.parentNode.removeChild(animationScript)
  }
  getCustomerCode(){
    return this.storageService.getCustomerCode()
    // return window.location.toString().split("://")[1].split('/')[1]
  }
  setCustomerCode(code:string){
    this.storageService.setCustomerCode(code)
  }
}
