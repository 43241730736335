import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }
  getTomorrowDate():Date
  {
    let currentDate=new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  }
  getTodayDate():Date
  {
    let currentDate=new Date();
    currentDate.setDate(currentDate.getDate());
    return currentDate;
  }
  getFirstDayCurrentMonth():Date
  {
    let currentDate=new Date();
    
    let startDate=new Date();
    startDate.setFullYear(currentDate.getFullYear());
    startDate.setMonth(currentDate.getMonth());
    startDate.setDate(1);
    return startDate;
  }
  getYearStartDay():Date
  {
    let currentDate=new Date();
    //alert(currentDate.getMonth());
    let startDate=new Date();
    startDate.setFullYear(currentDate.getFullYear());
    startDate.setMonth(0);
    startDate.setDate(1);
    return startDate;
  }
  getYearEndDay():Date
  {
    let currentDate=new Date();
    //alert(currentDate.getMonth());
    let startDate=new Date();
    startDate.setFullYear(currentDate.getFullYear());
    startDate.setMonth(11);
    startDate.setDate(31);
    return startDate;
  }

}
