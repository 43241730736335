
import { throwError as observableThrowError, Observable } from 'rxjs';
import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ResponseViewModel } from '../models/shared/response.model';
import { LocalizationService } from './localization.service';

@Injectable( {
  providedIn: 'root'
} )

export class ApiService {

  constructor( 
    private router: Router, 
    private tokenService: TokenService, 
    private http: HttpClient,
    private localizationService:LocalizationService
  ){

  }

  private setHeaders(withFiles:boolean=false): HttpHeaders {
    
    let headersConfig =
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'lang': this.localizationService.getLanguage(),
      'token': this.tokenService.getToken()
    };

    let headersConfigWithImage =
    {
      'Accept': 'application/json',
      'lang': 'en',
      'token': this.tokenService.getToken()
    };
    if(withFiles)
      return new HttpHeaders( headersConfigWithImage );
     else 
    return new HttpHeaders( headersConfig );
  }

  private formatErrors( error: any ) {


    if ( error.status == "401" ) {

    }
    else if ( error.status == "500" ) {
     
    }
    else if ( error.status == "404" ) {
     
    }
    return observableThrowError( error.json() );
  }

  get( path: string, params?: HttpParams ): Observable<ResponseViewModel> {
    return this.http.get( `${environment.api}${path}`, { headers: this.setHeaders(), params } ).pipe( catchError( er => this.formatErrors( er ) ), map( ( res: ResponseViewModel ) => res ) );
  }
  getImages( path: string ): Observable<Blob> {
    return this.http.get( `${environment.api}${path}`, { responseType: 'blob' } );
  }

  post( path: string, body: Object = {} ,withFiles=false): Observable<ResponseViewModel> {
    return this.http.post( `${environment.api}${path}`, body, { headers:this.setHeaders(withFiles) } )
      .pipe( catchError( this.formatErrors ), map( ( res: ResponseViewModel ) => res ) );
  }

  update( path: string, body: Object = {},withFiles=false ): Observable<ResponseViewModel> {
   
    return this.http.put( `${environment.api}${path}`, body, { headers: this.setHeaders(withFiles) } ).pipe( catchError( this.formatErrors ), map( ( res: ResponseViewModel ) => res ) );
  }

  remove( path: string ): Observable<ResponseViewModel> {
    return this.http.delete( `${environment.api}${path}`, { headers: this.setHeaders() } )
      .pipe( catchError( this.formatErrors ), map( ( res: ResponseViewModel ) => res ) );
  }

  removeAttachment( path: string ): Observable<ResponseViewModel> {
    return this.http.get( `${environment.api}${path}`, { headers: this.setHeaders() } ).pipe( map( ( res: ResponseViewModel ) => res ) );
  }
}
