<!-- <span class="mr-1 c-darkblue amp-5" [class]="lableClass" *ngIf="showNumber"></span> -->
<div class="d-inline-block star-ratings-sprite" [class]="starsClass"
    [style.height]="size+'px'" 
    [style.width]="(size * startsNumber)+'px'"
    [style.background-size]="size+'px'">

    <span class="star-ratings-sprite-rating" 
    [style.background-size]="size+'px'" 
    [style.height]="size+'px'"
    [style.width]="(rate/startsNumber*100)+'%'"></span>
</div>