import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizationService } from 'src/app/services/localization.service';
import { AgmCoreModule    } from '@agm/core';
import { environment } from 'src/environments/environment';
import { StarComponent } from './star/star.component';
import { MaxLengthDotPipe } from 'src/app/pipes/max-length-dot.pipe';

export function HttpLoaderFactory( http: HttpClient ) {
  return new TranslateHttpLoader( http, 'assets/i18n/', '.json' );
}

@NgModule({
  declarations: [
    LayoutComponent,StarComponent,MaxLengthDotPipe
  ],
  imports: [
    CommonModule,FormsModule,RouterModule
    ,ModalModule.forRoot(),
    HttpClientModule,DatepickerModule,BsDatepickerModule.forRoot()
    
    ,TranslateModule.forChild( {
      loader: {
        provide: TranslateModule,
        useFactory: ( HttpLoaderFactory ),
        deps: [HttpClient] 
      }, isolate: true 
    } ),
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
    }) ,
    ] ,
  providers:[],
  exports:[FormsModule,ReactiveFormsModule,RouterModule 
    ,ModalModule,HttpClientModule,AgmCoreModule
    ,DatepickerModule,BsDatepickerModule,TranslateModule,StarComponent
    ,MaxLengthDotPipe
    ]

})
export class SharedModule {
  constructor( private translate: TranslateService, private localizationService: LocalizationService ) {
    this.translate.use( localizationService.getLanguage() );
  }
}
