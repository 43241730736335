import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../components/shared/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'c',
        loadChildren: () => import('../components/customer/order/order.module').then(m => m.OrderModule)
      },
      {
        path: 'order',
        loadChildren: () => import('../components/customer/order-details/order-details.module').then(m => m.OrderDetailsModule)
      },
      {
        path: 'review',
        loadChildren: () => import('../components/customer/order-review/order-review.module').then(m => m.OrderReviewModule)
      },
      {
        path: 'cannot-deliver-location',
        loadChildren: () => import('../components/customer/cannot-deliver-location/cannot-deliver-location.module').then(m => m.CannotDeliverLocationModule)
      },
      {
        path: 'delivery-location',
        loadChildren: () => import('../components/customer/delivery-location/delivery-location.module').then(m => m.DeliveryLocationModule)
      },
      {
        path: 'new-location',
        loadChildren: () => import('../components/customer/new-location/new-location.module').then(m => m.NewLocationModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class  CustomerRoutingModule { }
